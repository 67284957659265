import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, Input, Label, Modal, Tab } from 'semantic-ui-react'
import { qs } from '../../../queries'
import { push_toast } from '../../../utils/common'
import { formUtils, getRemoteConfig, isPrivate } from '../../../utils/form'
import { removeAccents } from '../../../utils/Formatters'
import { gridDefaults } from '../../../utils/gridDefaults'
import PrivacyData from '../../../utils/PrivacyData'
import { person_actions } from '../people'
import PeopleFormPage from './PeopleFormPage'
import { getCondoLabors } from '../../../utils/DataService'
import { useAccessDeviceSettingsQuery } from '../../../hooks'
import * as Yup from 'yup'
import { Formik, FormikProps } from 'formik'
import { FF, FormErrorMsg, FormikDate, FormikInput, FormikSelect } from '../../../components/form/FormInputs'
import UnitsSelect from '../../../components/form/UnitsSelect'
import moment from 'moment'

const PeopleListModalPage = () => {
  const dispatch = useDispatch()
  const show_modal = useSelector((state: any) => state.people.showModal)
  const [show_new_modal, setShowNewModal] = useState(false)
  const { data: access_control_settings } = useAccessDeviceSettingsQuery()

  let person_show_new_button = getRemoteConfig(`general.gater__person__show_new_button`)

  const residents_query = useQuery<any[]>('people.allCached', () => qs.people.allCached(true), {
    onSuccess: () => {
      push_toast('Dados sincronizados', 'success')
    }
  })
  const residents_query_data = residents_query?.data ?? []

  const labors_query = useQuery<any[]>('labors.list', () => getCondoLabors())

  const [quick_filter_text, setQuickFilterText] = useState<any>(null)
  const [pendent_face_validation, setPendentFaceValidation] = useState(false)
  const [active_index, setActiveIndex] = useState(0)

  const getObject = (id: any) => dispatch(person_actions.get(id))
  const modalObject = (show: boolean) => dispatch(person_actions.modal(show))

  const handleEdit = (id) => {
    getObject(id)
    modalObject(true)
  }

  const closeModalPerson = () => {
    modalObject(false)
    dispatch(person_actions.current({}))
  }

  const onQuickFilterText = (e) => {
    let ev = removeAccents(e.target)
    setQuickFilterText(ev)
  }

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit()
  }

  const residents_columns_def = [
    {
      headerName: '#',
      field: 'obj_number',
      width: 40,
      filter: 'text'
    },
    {
      headerName: '#',
      field: 'id_in_equipment',
      width: 40,
      filter: 'text'
    },
    {
      headerName: 'Nome',
      field: 'name',
      width: 130,
      filter: 'text',
      getQuickFilterText: removeAccents
    },
    {
      headerName: 'CPF',
      field: 'cpf',
      width: 50,
      filter: 'text',
      valueFormatter: (params) => {
        return isPrivate('person__cpf') ? PrivacyData.maskPhone(params.value) : params.value
      }
    },
    {
      headerName: 'Unidades',
      field: 'units',
      width: 80,
      filter: 'text',
      valueGetter: (params) => params.data.units.map((uni) => uni.with_block)
    },
    {
      headerName: '',
      width: 100,
      suppressResize: true,
      suppressMovable: true,
      suppressSorting: true,
      suppressMenu: true,
      suppressFilter: true,
      suppressSizeToFit: true,
      cellRendererFramework: (params) => {
        return (
          <button className="ui button basic compact green floated right" onClick={() => handleEdit(params.data.id)}>
            Editar
          </button>
        )
      }
    }
  ]

  const labors_condo_columns_def = [
    {
      headerName: '#',
      field: 'obj_number',
      width: 40,
      filter: 'text'
    },
    {
      headerName: '#',
      field: 'id_in_equipment',
      width: 40,
      filter: 'text'
    },
    {
      headerName: 'Nome',
      field: 'name',
      width: 130,
      filter: 'text',
      getQuickFilterText: removeAccents
    },
    {
      headerName: 'CPF',
      field: 'cpf',
      width: 50,
      filter: 'text',
      valueFormatter: (params) => {
        return isPrivate('person__cpf') ? PrivacyData.maskPhone(params.value) : params.value
      }
    },
    {
      headerName: '',
      width: 100,
      suppressResize: true,
      suppressMovable: true,
      suppressSorting: true,
      suppressMenu: true,
      suppressFilter: true,
      suppressSizeToFit: true,
      cellRendererFramework: (params) => {
        return (
          <button className="ui button basic compact green floated right" onClick={() => handleEdit(params.data.id)}>
            Editar
          </button>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch(person_actions.current({}))
  }, [])

  return (
    <React.Fragment>
      <Modal size={'fullscreen'} open={true}>
        <Modal.Header>
          <Tab
            activeIndex={active_index}
            menu={{ inverted: true, attached: false, tabular: false }}
            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex as any)}
            panes={[
              { menuItem: 'Condôminos', render: () => <></> },
              { menuItem: 'Colaboradores Condôminio', render: () => <></> },
            ]}
          />

          &nbsp;

          <div className="page-modal-header">
            <Input icon="search" size="small" onChange={onQuickFilterText} placeholder="Filtrar..."/>

            &nbsp;&nbsp;&nbsp;&nbsp;

            {active_index === 0 &&
              <div>
                <Checkbox
                  toggle size="small" label="Filtrar Faces pendentes de validação"
                  checked={pendent_face_validation}
                  onChange={() => setPendentFaceValidation(!pendent_face_validation)}
                />
                &nbsp;
                <Label circular color="red">
                  {residents_query_data && residents_query_data.filter(i => i.face_validated === false && i.file_id).length}
                </Label>
              </div>}

            <div className="page-modal-buttons">
              {active_index === 0 && person_show_new_button &&
                <Button primary onClick={() => setShowNewModal(true)}>
                  Novo condômino
                </Button>}
              {active_index === 0 &&
                <Button primary onClick={() => residents_query.refetch()} loading={residents_query.isFetching} disabled={residents_query.isFetching}>
                  Sincronizar dados
                </Button>}
              <Button negative onClick={() => dispatch(person_actions.modal_list(false))}>Fechar</Button>
            </div>
          </div>
        </Modal.Header>

        <Modal.Content scrolling>
          <div style={{ width: '100%' }} className="ag-theme-material">
            {active_index === 0 &&
              <AgGridReact
                gridOptions={gridDefaults.adminGrid}
                columnDefs={residents_columns_def}
                rowData={
                  pendent_face_validation && residents_query_data
                    ? residents_query_data.filter(i => i.face_validated === false && i.file_id)
                    : residents_query_data
                }
                quickFilterText={quick_filter_text}
                onGridReady={onGridReady}
                getRowNodeId={(data) => data.id}/>}
            {active_index === 1 &&
              <AgGridReact
                gridOptions={gridDefaults.adminGrid}
                columnDefs={labors_condo_columns_def}
                rowData={
                  pendent_face_validation && labors_query.data
                    ? labors_query.data.filter(i => i.face_validated === false && i.file_id)
                    : labors_query.data
                }
                quickFilterText={quick_filter_text}
                onGridReady={onGridReady}
                getRowNodeId={(data) => data.id}/>}
          </div>
        </Modal.Content>
      </Modal>

      {show_modal &&
        <React.Fragment>
          <Modal size={'fullscreen'} open={true}>
            <Modal.Header>
              <div className="page-modal-header">
                Editar Morador

                <div className="page-modal-buttons">
                  <Button negative onClick={closeModalPerson}>Fechar</Button>
                </div>
              </div>
            </Modal.Header>
            <Modal.Content scrolling>
              <PeopleFormPage access_control_settings={access_control_settings}/>
            </Modal.Content>
          </Modal>

        </React.Fragment>}

      {show_new_modal && <NewPeopleModal closeModal={() => setShowNewModal(false)} refreshGrid={() => {
        residents_query.refetch()
      }}/>}
    </React.Fragment>
  )

}

const NewPeopleModal = (props) => {
  return (
    <React.Fragment>
      <Modal size={'large'} open={true}>
        <Modal.Header>
          Novo condômino
        </Modal.Header>

        <Formik
          initialValues={{
            name: '',
            status: 0,

            person_role: 3,
            type_person: '' as any,
            parent: '' as any,

            email: '',
            cpf: '',
            rg: '',
            birthday: '',
            gender: '',
            phone: '',
            mobile1: '',
            mobile2: '',

            same_address_condominium: true,

            cep: '',
            address: '',
            district: '',
            city: '',
            state: '',
            country: 'BRA',

            kinship: '',
            contact_name: '',
            contact_phone: '',
            contact_kinship: '',

            description: '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            unit_id_to_attach: Yup.string().required(),
            name: Yup.string().required(),
            status: Yup.string().required(),
            person_role: Yup.number().required(),
            type_person: Yup.string().required(),
            gender: Yup.string().matches(/(m|f|o)/, 'Opção inválida'),
            same_address_condominium: Yup.boolean().required(),

            cpf: Yup.string().when(
              ['person_role', 'status'],
              // @ts-ignore
              (person_role: number, status: number) => person_role === 3 && status !== 1 ? Yup.string() : Yup.string().required().validCPF()
            ),

            // @ts-ignore
            mobile1: Yup.string().validMobile(),
            // @ts-ignore
            phone: Yup.string().validPhone(),
            // @ts-ignore
            mobile2: Yup.string().validMobile()
          }, [['status', 'mobile1'], ['status', 'email'], ['mobile1', 'email']])}
          onSubmit={async (values: any) => {
            await qs.people.create(values)
            props.closeModal()
            props.refreshGrid()
          }}
        >
          {({ values, errors, handleBlur, handleChange, ...formikBag }: FormikProps<any>) => {

            return (
              <>

                <Modal.Content scrolling>

                  <Form error>
                    <div className="ui divider hidden"/>
                    <UnitsSelect name="unit_id_to_attach" label="Unidade"/>


                    <h4 className="ui block header">Dados Pessoais</h4>
                    <div className="ui divider hidden"/>

                    <Form.Group>
                      <Form.Field error={formUtils.handleError(errors.type_person)} width={4}>
                        <label>Tipo da Pessoa:</label>
                        <Form.Group inline>
                          <Form.Radio label="Física" value={1} name="type_person" id="type1"
                                      checked={parseInt(`${values.type_person}`) === 1} onChange={handleChange}
                                      onBlur={handleBlur} error={formUtils.handleError(errors.type_person)}/>
                          <Form.Radio label="Jurídica" value={2} name="type_person" id="type2"
                                      checked={parseInt(`${values.type_person}`) === 2} onChange={handleChange}
                                      onBlur={handleBlur} error={formUtils.handleError(errors.type_person)}/>
                        </Form.Group>
                        <FormErrorMsg error={errors.type_person}/>
                      </Form.Field>

                      <FF name="name" component={FormikInput} label={'Nome'} width={12}/>
                    </Form.Group>

                    <Form.Group widths="equal">
                      <FF name="email" component={FormikInput} label={'E-mail'}/>
                      <FF name="rg" component={FormikInput} label={'RG'}/>

                      <FF
                        name="cpf"
                        component={FormikInput}
                        label={<>
                          CPF/CNPJ (Somente números)
                          <Button type="button" size="mini" icon="check" style={{ padding: '2px' }} onClick={async () => {
                            const cpf_data = await qs.people.validateDoc(values.cpf)
                            if (cpf_data) {
                              let Name = cpf_data?.Name
                              let TaxIdNumber = cpf_data?.TaxIdNumber
                              let Gender = String(cpf_data?.Gender).toLowerCase()
                              let BirthDate = moment(cpf_data?.BirthDate).format('YYYY-MM-DD')

                              formikBag.setFieldValue('name', Name)
                              formikBag.setFieldValue('cpf', TaxIdNumber)
                              formikBag.setFieldValue('birthday', BirthDate)
                              formikBag.setFieldValue('gender', Gender)
                            }
                          }}/>
                        </>}
                      />
                      {/*<Button*/}
                      {/*  type="button" primary content="Validar na RF"*/}
                      {/*  onClick={() => this.validateData(values.cpf, values.birthday, formikBag)}*/}
                      {/*  loading={this.state.validatingRf}*/}
                      {/*/>*/}
                    </Form.Group>

                    <Form.Group widths={'equal'}>
                      <FF label={'Data de Nascimento'} name="birthday" component={FormikDate}/>

                      <FF label={'Sexo'} name="gender" component={FormikSelect} options={formUtils.genders}/>
                    </Form.Group>

                    <FF name="kinship" component={FormikInput} label="Parentesco do Responsável"/>
                    <FF name="description" component={FormikInput} label="Observações"/>


                    <h4 className="ui block header">Contato</h4>
                    <div className="ui divider hidden"/>

                    <Form.Group widths={'equal'}>
                      <FF name="phone" component={FormikInput} label={'Telefone'} mask={formUtils.phoneMask}
                          onChange={(event: any) => formikBag.setFieldValue('phone', formUtils.unMaskNumbers(event.target.value))}/>
                      <FF name="mobile1" component={FormikInput} label={'Celular 1'} mask={formUtils.mobileMask}
                          onChange={(event: any) => formikBag.setFieldValue('mobile1', formUtils.unMaskNumbers(event.target.value))}/>
                      <FF name="mobile2" component={FormikInput} label={'Celular 2'} mask={formUtils.mobileMask}
                          onChange={(event: any) => formikBag.setFieldValue('mobile2', formUtils.unMaskNumbers(event.target.value))}/>
                    </Form.Group>


                    <h4 className="ui block header">Pessoa para Contato</h4>
                    <div className="ui divider hidden"/>

                    <Form.Group widths={'equal'}>
                      <FF name="contact_name" component={FormikInput} label={'Nome do Contato'}/>
                      <FF name="contact_phone" component={FormikInput} label={'Telefone do Contato com DDD'}/>
                      <FF name="contact_kinship" component={FormikInput} label={'Parentesco do Contato'}/>
                    </Form.Group>
                  </Form>

                </Modal.Content>

                <Modal.Actions>
                  <Button negative onClick={props.closeModal}>Fechar</Button>
                  <Button primary onClick={formikBag.submitForm}>Salvar</Button>
                </Modal.Actions>

              </>
            )
          }}
        </Formik>

      </Modal>

    </React.Fragment>
  )
}

export default React.memo(PeopleListModalPage)
